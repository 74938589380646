import { Platform } from '@angular/cdk/platform';
import { Injectable } from '@angular/core';

import { CryptoService } from '../crypto/crypto.service';
import { MemoryStorage } from '../memory-storage/memory-storage.service';

@Injectable({
    providedIn: 'root',
})
export class LocalStorageService {
    private readonly storage: Storage;

    constructor(
        private _platform: Platform,
        private cryptoService: CryptoService,
    ) {
        this.storage =
            this._platform.isBrowser && window?.localStorage
                ? window.localStorage
                : new MemoryStorage();
    }

    get length(): number {
        return this.storage.length;
    }

    /**
     * Delete all items from local storage.
     */
    clear(): void {
        this.storage.clear();
    }

    /**
     * Returns the current value associated with the given key, or null if the given key does not exist.
     * Can return the encrypted value in case of validations with conditionals.
     *
     * @param {string} key - The key of the item to get.
     * @param {boolean} decrypted - The value to get the item decrypted.
     */
    getItem(key: string, decrypted?: boolean): any | null {
        const data: string | null = this.storage.getItem(key);
        if (data == undefined) return null;
        if (!decrypted) return data;
        return this.cryptoService.decrypted(data);
    }

    /**
     * Returns the name of the nth key, or null if n is greater than or equal to the number of key/value pairs.
     *
     *
     * @param {number} index - The key of the item to get.
     */
    key(index: number): string | null {
        return this.storage.key(index);
    }

    /**
     * Removes the key/value pair with the given key, if a key/value pair with the given key exists.
     *
     * Dispatches a storage event on Window objects holding an equivalent Storage object.
     *
     * @param {string} index - The key of the item to get.
     */
    removeItem(key: string): void {
        this.storage.removeItem(key);
    }

    /**
     * Sets the value of the pair identified by key to value, creating a new key/value pair if none existed for key previously.
     *
     * @param {string} key - The key of the item to get.
     * @param {string} value - The key of the item to get.
     */
    setItem(key: string, value: string): void {
        this.storage.setItem(key, this.cryptoService.encrypted(value));
    }
}
